import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ClickImage from "components/ClickImage";
import { useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { UserContext } from "views/User.context";
import { useMenuButton, useMenuList } from "../../hooks";
import styles from "./Menubar.module.scss";
import Submenu from "./Submenu";

const logoLarge = "/img/logo-lg.png";
const logoSmall = "/img/logo-sm.png";

export const MenuLogo = () => (
  <a
    className={styles.menuLogo}
    href={process.env.REACT_APP_PORTAL_URL}
    role="menuitem"
  >
    <ClickImage
      defaultImg={logoLarge}
      alt={"Clickideia"}
      md={logoSmall}
      sm={logoSmall}
    />
  </a>
);

const MenubarItem = ({
  children,
  submenu,
  itemIndex,
  selectedItemIndex,
  setSelectedItemIndex,
  link,
}) => {
  const { buttonProps, popperProps } = useMenuButton(
    itemIndex,
    selectedItemIndex,
    setSelectedItemIndex,
  );

  return (
    <li className={styles.menubarItemWrap} role="none">
      {submenu ? (
        <>
          <button {...buttonProps} className={styles.menubarItem}>
            {children}
            {submenu && (
              <div
                className={classnames(styles.submenuIndicator, {
                  [styles.selected]: selectedItemIndex === itemIndex,
                })}
              >
                <FontAwesomeIcon icon="chevron-down" />
              </div>
            )}
          </button>
          <Submenu
            {...{
              ...popperProps,
              isSelected: selectedItemIndex === itemIndex,
              items: submenu,
              label: children,
              className: styles.menubarItemSubmenu,
            }}
          />
        </>
      ) : (
        <a className={styles.menubarItem} href={link}>
          {children}
        </a>
      )}
    </li>
  );
};

const Menubar = () => {
  const user = useContext(UserContext);
  const OLD_HOST = process.env.REACT_APP_PORTAL_URL;

  const menuItems = !user.type
    ? [
        {
          label: "INFORMAÇÕES CONTEXTUALIZADAS",
          link: `${OLD_HOST}/portal/ic/`,
        },
      ]
    : [
        {
          label: "ETAPAS DE ENSINO",
          submenu: [
            {
              label: "Educação Infantil e Anos iniciais",
              submenu: [
                {
                  label: "Ambiente antigo",
                  link: `${OLD_HOST}/sg/f1_Home.php`,
                },
                { label: "Ambiente novo", link: "/anosiniciais" },
                { label: "Arte", link: "/anosiniciais/interativas/art" },
                {
                  label: "Ciências da Natureza",
                  link: "/anosiniciais/interativas/cie",
                },
                {
                  label: "Educação Física",
                  link: "/anosiniciais/interativas/efi",
                },
                { label: "Geografia", link: "/anosiniciais/interativas/geo" },
                { label: "História", link: "/anosiniciais/interativas/his" },
                {
                  label: "Língua Inglesa",
                  link: "/anosiniciais/interativas/ing",
                },
                { label: "Matemática", link: "/anosiniciais/interativas/mat" },
                {
                  label: "Língua Portuguesa",
                  link: "/anosiniciais/interativas/por",
                },
                {
                  label: "Libras",
                  link: "/anosiniciais/interativas/lib",
                },
                {
                  label: "Ludimundi",
                  link: `${OLD_HOST}/portal/infantil/ludimundi/hotsite/`,
                },
              ],
            },
            {
              label: "Fundamental Anos Finais",
              submenu: [
                {
                  label: "Arte",
                  link: `${OLD_HOST}/portal/conteudos/47`,
                },
                {
                  label: "Ciências da Natureza",
                  link: `${OLD_HOST}/portal/conteudos/24`,
                },
                {
                  label: "Educação Física",
                  link: `${OLD_HOST}/portal/conteudos/25`,
                },
                {
                  label: "Geografia",
                  link: `${OLD_HOST}/portal/conteudos/26`,
                },
                {
                  label: "História",
                  link: `${OLD_HOST}/portal/conteudos/27`,
                },
                {
                  label: "Língua Inglesa",
                  link: `${OLD_HOST}/portal/conteudos/28`,
                },
                {
                  label: "Matemática",
                  link: `${OLD_HOST}/portal/conteudos/29`,
                },
                {
                  label: "Língua Portuguesa",
                  link: `${OLD_HOST}/portal/conteudos/30`,
                },
                {
                  label: "Temas transversais",
                  link: `${OLD_HOST}/portal/conteudos/61`,
                },
                {
                  label: "Ensino Religioso",
                  link: `${OLD_HOST}/portal/conteudos/64`,
                },
              ],
            },
            {
              label: "Ensino Médio",
              submenu: [
                {
                  label: "Ciências Humanas",
                  link: `${OLD_HOST}/portal/conteudos/a/1`,
                },
                {
                  label: "Ciências da Natureza",
                  link: `${OLD_HOST}/portal/conteudos/a/2`,
                },
                {
                  label: "Linguagens",
                  link: `${OLD_HOST}/portal/conteudos/a/4`,
                },
                {
                  label: "Matemática",
                  link: `${OLD_HOST}/portal/conteudos/a/3`,
                },
                {
                  label: "Temas transversais",
                  link: `${OLD_HOST}/portal/conteudos/45`,
                },  {
                  label: "Se liga no Enem",
                  link: `https://enemclickideia.com/`,
                },
              ],
            },
          ],
        },
        {
          label: "CONTEÚDOS ESPECIAIS",
          submenu: [
            { label: "Click Redação", link: `${OLD_HOST}/sg/red_Index.php` },
            {
              label: "Click Vestibulares",
              link: `${OLD_HOST}/sg/ck_VestibularIndex.php`,
            },
            { label: "Click Enem", link: `${OLD_HOST}/portal/enem/` },
            {
              label: "Informações Contextualizadas",
              link: `${OLD_HOST}/portal/ic/`,
            },
            {
              label: "Galeria de Jogos",
              link: `${OLD_HOST}/portal/galerias/jogos/`,
            },
            {
              label: "Galeria de Podcasts",
              link: `${OLD_HOST}/portal/galerias/podcast/`,
            },
            {
              label: "Galeria de Vídeos",
              link: `${OLD_HOST}/portal/galerias/videos/`,
            },
            // {
            //   label: "Microscópio Virtual",
            //   link: `${OLD_HOST}/sg/ferramentas/microscopio_virtual/tela_microscopio2.html`,
            // },
            // {
            //   label: "Corpo Humano",
            //   link: `${OLD_HOST}/sg/ferramentas/corpo_humano/`,
            // },
          ],
        },
        {
          label: "FERRAMENTAS",
          submenu: [
            { label: "Baú de Ideias", link: `${OLD_HOST}/sg/bau_Inicio.php` },
            { label: "Blog", link: `${OLD_HOST}/portal/blog/` },
            { label: "Escrita Colaborativa", link: `/EscritaColaborativa` },
            { label: "Editor de HQ", link: `${OLD_HOST}/sg/hq_Home.php` },
            { label: "Editor de Jornal", link: `/EditorDeJornal` },
            {
              label: "Exercícios Online",
              link: `${OLD_HOST}/portal/exercicios_online/`,
            },
            { label: "Palavras Cruzadas", link: `/PalavrasCruzadas` },
            {
              label: "Roteiro de Aprendizagem",
              link: `/RoteiroDeAprendizagem`,
            },
            { label: "WebQuests", link: `/WebQuests` },
          ],
        },
        ["teacher", "employee", "manager", "admin"].includes(user.type) && {
          label: "ÁREA DO PROFESSOR",
          submenu: [
            ["employee", "manager", "admin"].includes(user.type) && {
              label: "ClickAcessos",
              link: `${OLD_HOST}/portal/dashboard/clickacessos`,
            },
            {
              label: "Mural",
              link: `${OLD_HOST}/portal/mural/`,
            },
            {
              label: "Gestão de Usuários",
              link: `${OLD_HOST}/portal/gestao/usuarios`,
            },
            ["teacher"].includes(user.type) && {
              label: "Material de Apoio",
              link: `${OLD_HOST}/portal/conteudos/a/5`,
            },
          ].filter((el) => el !== false),
        },
      ].filter((el) => el !== false);

  const { listProps, selectedItemIndex, setSelectedItemIndex } = useMenuList({
    role: "menubar",
    label: "Navegação principal",
  });

  return (
    <div className={styles.menubarWrap}>
      <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="unset">
        <ul {...listProps} className={styles.menubar}>
          <li role="none" className={styles.menubarLogoWrap}>
            <MenuLogo />
          </li>
          {menuItems.map(({ label, submenu }, itemIndex) => (
            <MenubarItem
              key={itemIndex}
              {...{
                submenu,
                itemIndex,
                selectedItemIndex,
                setSelectedItemIndex,
              }}
            >
              {label}
            </MenubarItem>
          ))}
        </ul>
      </Scrollbars>
    </div>
  );
};

export default Menubar;
